import { graphql } from 'gatsby';
import React, { useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';
import DoubleColumnText from '../components/common/DoubleColumnText';
import FooterMountains from '../components/common/FooterMountains';
import Header from '../components/common/Header';
import KeyNumbers from '../components/common/KeyNumbers';
import Push from '../components/common/Push';
import PushList from '../components/common/PushList';
import Text from '../components/common/Text';
import VerticalLine from '../components/common/VerticalLine';
import LayoutCt from '../components/layout';
import SEO from '../components/SEO/SEO';
import colors from '../styles/colors.js';
import useViewport from '../utils/useViewport';

const Layout = styled(LayoutCt)`
    background: ${colors.dark_blue};
    .intro {
        margin-top: 20vw;
        margin-bottom: 70px;
    }
    .wealthManagement {
        z-index: 3;
        grid-row: 4;
        .title-label {
            color: white;
        }
    }
    .milkyWay {
        position: relative;
        grid-column: 1/4;
        grid-row: 4;
        width: 100%;
        z-index: 2;

        img {
            position: absolute;
            width: 100%;
            height: 90%;
            object-fit: cover;
            transition: transform 800ms ease-in-out;
        }
        video {
            display: none;
        }
        .gradient {
            width: 100%;
            height: 91%;
            position: absolute;
            top: 0;
            background: linear-gradient(
                to bottom,
                ${colors.transparent_dark_blue},
                ${colors.dark_blue} 100%
            );
        }
    }
    .debug {
        height: 0px;
        margin-top: 0;
    }
    @media screen and (min-width: 800px) {
        .intro {
            margin-top: 6vw;
        }
        .wealthManagement {
            z-index: 3;
            margin-bottom: 120px;
            margin-top: 150px;
        }
        .milkyWay {
            grid-column: 1/15;
            position: relative;
            &:not(.anim) {
                video {
                    width: 70%;
                    transform: translateX(-50%);
                }
            }
            video {
                left: 0%;
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all 600ms ease-in-out;
                left: 50%;
                transform: translateX(-50%);
            }
            .gradient {
                height: 101%;
            }
        }
        .line {
            margin-top: 0;
        }
    }
`;

export const query = graphql`
    query($lang: WpLanguageCodeEnum) {
        allWpPage(
            filter: {
                language: { code: { eq: $lang } }
                template: { templateName: { eq: "Homepage" } }
            }
        ) {
            edges {
                node {
                    seo {
                        description: metaDesc
                        title
                    }
                    acfPageHomepage {
                        double_column_text {
                            images {
                                image_desktop {
                                    filesize
                                    height
                                    mime_type
                                    title
                                    url
                                    width
                                }
                                image_mobile {
                                    filesize
                                    height
                                    mime_type
                                    title
                                    url
                                    width
                                }
                            }
                            label
                            link {
                                label
                                page
                                target
                                type
                                url
                            }
                            position_image
                            text_left
                            text_right
                            title
                        }
                        header {
                            subtitle
                            title
                            type
                        }
                        key_numbers {
                            label
                            list_number {
                                legend
                                number
                            }
                        }
                        list_push {
                            array_push {
                                title
                            }
                            label
                            link {
                                label
                                page
                                target
                                type
                                url
                            }
                        }
                        push {
                            label
                            link {
                                label
                                page
                                target
                                type
                                url
                            }
                        }
                        text {
                            label
                            text
                            link {
                                label
                                page
                                target
                                type
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`;

const Homepage = ({ data, className, pageContext }) => {
    const seo = data.allWpPage.edges[0].node.seo;
    data = data.allWpPage.edges[0].node.acfPageHomepage;

    const { width } = useViewport();

    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0.2,
        triggerOnce: true,
    });

    const video = useRef(null);
    if (inView && video.current) {
        video.current.play();
    }

    return (
        <Layout
            lang={pageContext.lang}
            pathTranslatePage={pageContext.pathTranslatePage}
            className={className}
        >
            <SEO
                lang={seo.lang}
                title={seo.title}
                description={seo.description}
                translations={pageContext.translations}
            />
            <Header className="header" data={data.header} />
            <Text className="intro" data={data.text} />
            <DoubleColumnText
                className="wealthManagement"
                data={data.double_column_text}
            />
            {width < 800 ? (
                <div className="milkyWay">
                    <img
                        src="/images/homepage/mobile/milky_way.jpg"
                        alt="milky way"
                    />
                    <div className="gradient"></div>
                </div>
            ) : (
                <div
                    className={inView ? 'milkyWay anim' : 'milkyWay'}
                    ref={ref}
                >
                    <video
                        src="/video/milky-way.mp4"
                        ref={video}
                        loop
                        muted
                        playbackRate="-1"
                    ></video>
                    <div className="gradient"></div>
                </div>
            )}
            <VerticalLine className="debug" />
            <PushList
                className="assetManagement"
                data={data.list_push}
                lang={pageContext.lang}
            />
            <VerticalLine />
            <KeyNumbers className="keyNumbers" data={data.key_numbers} />
            <VerticalLine />
            <Push data={data.push} />
            <FooterMountains />
        </Layout>
    );
};

export default Homepage;
